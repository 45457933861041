import React from "react"
import styled from "styled-components"

import Layout from "../components/LayoutComponent"
import Bandcamp from "../components/Bandcamp"
import Seo from "../components/Seo"

const MusicContent = styled.div`
  margin-bottom: 1em;
`

const StyledMusicLinks = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const YouTube = styled.iframe`
  margin: 1.5rem 0 1.5rem 0;
  height: 350px;
  width: 65%;
  @media (max-width: 568px) {
    width: 90vw;
  }
`

const youTubeLinks = [
  "https://www.youtube-nocookie.com/embed/o2oxF09WYas",
  "https://www.youtube-nocookie.com/embed/VN_i5m7qIt8",
  "https://www.youtube-nocookie.com/embed/UOulZibNq1w",
  "https://www.youtube-nocookie.com/embed/r7oXIL5Bny0",
]

const Music = () => {
  return (
    <Layout>
      <Seo title="Music" />

      <MusicContent>
        On this page you will find examples of my music in audio and video
        recordings. If you are interested in purchasing a digital download of my
        album please visit my{" "}
        <a href="https://johannmcbee.bandcamp.com">Bandcamp page</a>.
      </MusicContent>
      <StyledMusicLinks>
        <Bandcamp height="485px" width="500px" tracklist="true" />
        {youTubeLinks.map(link => {
          return (
            <YouTube
              key={link}
              src={link}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></YouTube>
          )
        })}
      </StyledMusicLinks>
    </Layout>
  )
}

export default Music
